import EasyChatGPTWidget from '@/components/EasyChatGPTWidget'
import { LocalStorageKeys } from '@/constants'
import { AuthProvider, CartProvider, StoreProvider } from '@/containers'
import { FlashSaleProvider } from '@/containers/FlashSaleContainer'
import { NotificationProvider } from '@/containers/NotificationContainer'
import '@/styles/global.css'
import * as fbq from '@/utils/fpixel'
import { setCookie } from 'cookies-next'
import { NextIntlProvider } from 'next-intl'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Exo, Mulish } from 'next/font/google'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
const PopupMain = dynamic(() => import('@/components/PopupMain'), {
  ssr: false,
})
NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})

const mulishFont = Mulish({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-mulish',
})

const exoFont = Exo({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-exo',
})
const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient())
  const router = useRouter()
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageView()
    const handleRouteChange = () => {
      fbq.pageView()
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  useEffect(() => {
    if (router.query.app) {
      localStorage?.setItem('app', router?.query?.app as string)
      setCookie('app', router?.query?.app as string, { maxAge: 2147483647 })
      setCookie('channel', router?.query?.app as string, { maxAge: 2147483647 })
    }
  }, [router])
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const version = localStorage?.getItem(LocalStorageKeys.VERSION) || null
      if (version !== process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION) {
        localStorage?.clear()
        localStorage?.setItem(
          LocalStorageKeys.VERSION,
          process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION as string
        )
      }
    }
  })

  useEffect(() => {
    const handleStart = () => {
      NProgress.start()
    }

    const handleStop = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)
    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  return (
    <NotificationProvider>
      <Script
        id='liveChat'
        dangerouslySetInnerHTML={{
          __html: `
          !function(s,u,b,i,z){var o,t,r,y;s[i]||(s._sbzaccid=z,s[i]=function(){s[i].q.push(arguments)},s[i].q=[],s[i]("setAccount",z),r=["widget.subiz.net","storage.googleapis"+(t=".com"),"app.sbz.workers.dev",i+"a"+(o=function(k,t){var n=t<=6?5:o(k,t-1)+o(k,t-3);return k!==t?n:n.toString(32)})(20,20)+t,i+"b"+o(30,30)+t,i+"c"+o(40,40)+t],(y=function(k){var t,n;s._subiz_init_2094850928430||r[k]&&(t=u.createElement(b),n=u.getElementsByTagName(b)[0],t.async=1,t.src="https://"+r[k]+"/sbz/app.js?accid="+z,n.parentNode.insertBefore(t,n),setTimeout(y,2e3,k+1))})(0))}(window,document,"script","subiz", "acqqxqpmqjxhbepcyrmr")
          `,
        }}
      />
      <Script src={process.env.NEXT_PUBLIC_CYBER_SOURCE_FORM} />
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        strategy='afterInteractive'
      />
      <EasyChatGPTWidget />
      <NextIntlProvider messages={pageProps.messages}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider authToken={pageProps.token}>
            <CartProvider>
              <StoreProvider {...pageProps}>
                <FlashSaleProvider {...pageProps}>
                  <DefaultSeo
                    title={process.env.NEXT_SEO_TITLE}
                    description={process.env.NEXT_SEO_DESCRIPTION}
                    canonical={process.env.NEXT_SEO_CANONICAL}
                    openGraph={{
                      url: router.basePath,
                      title: process.env.NEXT_SEO_TITLE,
                      description: process.env.NEXT_SEO_DESCRIPTION,
                      images: [
                        {
                          url:
                            pageProps?.systemConfig?.seoImageDefault ||
                            process.env.NEXT_SEO_IMAGE ||
                            '',
                        },
                      ],
                      site_name: router.basePath,
                      type: 'website',
                      locale: 'vi_IE',
                      siteName: router.basePath,
                    }}
                    additionalMetaTags={[
                      {
                        name: 'keywords',
                        content: process.env.NEXT_SEO_KEYWORD || '',
                      },
                    ]}
                  />
                  <div className={`root_app font-mulish scrollbar-hide`}>
                    <style jsx global>{`
                      :root {
                        --font-mulish: ${mulishFont.style.fontFamily};
                        --font-exo: ${exoFont.style.fontFamily};
                      }
                    `}</style>

                    <Component {...pageProps} />
                  </div>
                  <ToastContainer
                    position='top-center'
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='light'
                  />
                </FlashSaleProvider>
              </StoreProvider>
            </CartProvider>
            <PopupMain />
          </AuthProvider>
        </QueryClientProvider>
      </NextIntlProvider>
    </NotificationProvider>
  )
}
export default App
